<template>
  <div class="row child-component">
    <b-card
      class="col-lg-8 mx-auto"
      @paste="pasteDataFromClipboard($event)"
    >
      <b-row>
        <div class="col-lg-7 table-mandatory align-self-center">
          Press enter to add another row to the table.
        </div>
        <div class="col-lg-5 button-add-product text-right">
          <button
            @click="openFileUpload()"
            class="mr-2 text-uppercase btn custom-button btn-primary"
          >
            Excel Upload
          </button>
        </div>
      </b-row>
      <b-table
        striped
        hover
        :fields="fields"
        :items="ClientProductList"
        :current-page="currentPage"
        :per-page="perPage"
        show-empty
        responsive
        id="product-items"
      >
        <template v-slot:empty="scope">
          <label
            class="empty-table-text"
            @paste="pasteDataFromClipboard($event)"
            >Add products to populate the table</label
          >
        </template>
        <template v-slot:cell(ProductName)="data">
          <b-form-input
            :maxlength="20"
            v-model="data.item.ProductName"
            placeholder="Enter Product Name"
            @blur="$v.ClientProductList.$each.$iter[GetRowIndex(data.index)].ProductName.$touch()"
            @focus="ResetListDirty(data.index,'ProductName')"
            v-bind:class="{
                    errorborder:  $v.ClientProductList.$each.$iter[GetRowIndex(data.index)].ProductName.$invalid && $v.ClientProductList.$each.$iter[GetRowIndex(data.index)].ProductName.$dirty,
                    noterrorborder: !$v.ClientProductList.$each.$iter[GetRowIndex(data.index)].ProductName.$invalid,
                  }"
          ></b-form-input>
          <div
            class="error-message-format"
            v-if="
              !$v.ClientProductList.$each.$iter[GetRowIndex(data.index)].ProductName.required
              && $v.ClientProductList.$each.$iter[GetRowIndex(data.index)].ProductName.$dirty
            "
          >
            Required field
          </div>
          <div
            class="error-message-format"
            v-if="
              !$v.ClientProductList.$each.$iter[GetRowIndex(data.index)].ProductName
                .maxLength
                && $v.ClientProductList.$each.$iter[GetRowIndex(data.index)].ProductName.$dirty
            "
          >
            Product Name should be
            {{
              $v.ClientProductList.$each.$iter[GetRowIndex(data.index)].ProductName.$params
                .maxLength.max

            }}
            characters max.

          </div>
        </template>
        <template v-slot:cell(ProductCode)="data">
          <b-form-input
            :maxlength="3"
            :minlength="2"
            v-model="data.item.ProductCode"
            placeholder="Enter Code"
            @blur="$v.ClientProductList.$each.$iter[GetRowIndex(data.index)].ProductCode.$touch()"
            @focus="ResetListDirty(data.index,'ProductCode')"
             v-bind:class="{
                    errorborder:  $v.ClientProductList.$each.$iter[GetRowIndex(data.index)].ProductCode.$invalid && $v.ClientProductList.$each.$iter[GetRowIndex(data.index)].ProductCode.$dirty,
                    noterrorborder: !$v.ClientProductList.$each.$iter[GetRowIndex(data.index)].ProductCode.$invalid,
                  }"
          ></b-form-input>
          <div
            class="error-message-format"
            v-if="
              !$v.ClientProductList.$each.$iter[GetRowIndex(data.index)].ProductCode.required
              && $v.ClientProductList.$each.$iter[GetRowIndex(data.index)].ProductCode.$dirty
            "
          >
            Required field
          </div>
          <div
            class="error-message-format"
            v-if="
              !$v.ClientProductList.$each.$iter[GetRowIndex(data.index)].ProductCode
                .maxLength
                && $v.ClientProductList.$each.$iter[GetRowIndex(data.index)].ProductCode.$dirty
            "
          >
            Product Code should be
            {{
              $v.ClientProductList.$each.$iter[GetRowIndex(data.index)].ProductCode.$params
                .maxLength.max
            }}
            characters max.
          </div>
          <div
            class="error-message-format"
            v-if="
              !$v.ClientProductList.$each.$iter[GetRowIndex(data.index)].ProductCode
                .minLength
                && $v.ClientProductList.$each.$iter[GetRowIndex(data.index)].ProductCode.$dirty
            "
          >

            Input must be at least {{
              $v.ClientProductList.$each.$iter[GetRowIndex(data.index)].ProductCode.$params
                .minLength.min
            }} characters
          </div>
          <div
            class="error-message-format"
            v-if="
              !$v.ClientProductList.$each.$iter[GetRowIndex(data.index)].ProductCode
                .unique
                && $v.ClientProductList.$each.$iter[GetRowIndex(data.index)].ProductCode.$dirty
            "
          >
            This product code already exists.
          </div>
        </template>
        <template v-slot:cell(Actions)="data">
          <div class="text-center action-cell">
            <!-- <b-img
              :src="editIcon"
              @click="EditProduct(data.index)"
              class="Custom-Edit"
            /> -->
            <b-img
              :src="deleteIcon"
              @click="DeleteProduct(data.index)"
              class="Custom-Delete"
            />
          </div>
        </template>
        <template v-slot:cell(Media)="data">
          <div class="text-left">
            {{ data.item.Media.map((s) => s.MediaType).join(",") }}
          </div>
        </template>
        <template v-slot:cell(ProductBillingAddress)="data">
          <div v-if="BillingPartyNotTimeOnly">
            <div v-if="data.item.AddedBillingException">
              <b-img
                :src="editIcon"
                @click="ShowBillingCommissionException(data.index, false)"
                class="Custom-Edit"
              />
              <span
                v-if="
                  data.item.ProductBillingAddress.Country !=
                  CountryUnitedStatesValue
                "
                class="exception-body"
              >
                <span>
                  {{ data.item.ProductBillingAddress.AddressLine1 }},{{
                    data.item.ProductBillingAddress.AddressLine2
                  }},{{ data.item.ProductBillingAddress.CountryName }}
                </span>
              </span>
              <span v-else class="exception-body">
                <span v-if="TruncatedBillingStreetAddress != null">
                  {{
                    data.item.ProductBillingAddress
                      .TruncatedBillingStreetAddress
                  }}, {{ data.item.ProductBillingAddress.City }},{{
                    data.item.ProductBillingAddress.StateName
                  }},{{ data.item.ProductBillingAddress.ZipCode }}
                </span>
                <span v-else>
                  {{ data.item.ProductBillingAddress.StreetAddress }},
                  {{ data.item.ProductBillingAddress.City }},{{
                    data.item.ProductBillingAddress.StateName
                  }},{{ data.item.ProductBillingAddress.ZipCode }}
                </span>
              </span>
            </div>
            <div v-else>
              <button
                class="btn btn-link"
                @click="ShowBillingCommissionException(data.index, false)"
              >
                Add
              </button>
            </div>
          </div>
          <div v-else class="text-center">-</div>
        </template>
        <template v-slot:cell(CommissionInfo)="data">
          <div v-if="BillingPartyNotTimeOnly">
            <div v-if="data.item.AddedCommissionException">
              <b-img
                :src="editIcon"
                @click="ShowBillingCommissionException(data.index, true)"
                class="Custom-Edit"
              />&nbsp;
              <span
                class="text-left"
                v-if="
                  data.item.CommissionInfo.CommissionBasis == 'Net' ||
                  data.item.CommissionInfo.CommissionBasis == 'Gross'
                "
              >
                <span
                  >Commission:
                  {{ data.item.CommissionInfo.CommissionPercentage }}%</span
                >
              </span>
              <span class="text-center" v-if="data.item.CommissionInfo.FeeOnly">
                <span v-if="data.item.CommissionInfo.FeeOnly">Fee Only</span>
              </span>
              <div class="text-center" v-if="data.item.CommissionInfo.TimeOnly">
                <div v-if="data.item.CommissionInfo.TimeOnly == 'true'">
                  Time Only
                </div>
              </div>
            </div>
            <div v-else>
              <button
                class="btn btn-link"
                @click="ShowBillingCommissionException(data.index, true)"
              >
                Add
              </button>
            </div>
          </div>
          <div v-else class="text-center">-</div>
        </template>
        <template v-slot:cell(ProductExceptions)="data">
          <div
            v-if="
              data.item.AddedBillingException ||
              data.item.AddedCommissionException
            "
            class="exception-circle"
          >
            <span>
              <button
                class="btn btn-link custom-link-button"
                :id="'billing-exceptions-info' + data.index"
              >
                <span class="circle-data">{{
                  (data.item.AddedBillingException ? 1 : 0) +
                    (data.item.CommissionInfo.CommissionBasis == "Net"
                      ? 1
                      : 0) +
                    (data.item.CommissionInfo.CommissionBasis == "Gross"
                      ? 1
                      : 0) +
                    (data.item.CommissionInfo.FeeOnly ? 1 : 0) >
                  1
                    ? 2
                    : 1
                }}</span>
              </button>
              <b-popover
                triggers="hover"
                placement="top"
                display="inline"
                :target="'billing-exceptions-info' + data.index"
                variant="primary"
              >
                <div
                  class="text-left"
                  v-if="
                    data.item.CommissionInfo.CommissionBasis == 'Net' ||
                    data.item.CommissionInfo.CommissionBasis == 'Gross'
                  "
                >
                  <div class="exception-title">Commission Basis</div>
                  <div>{{ data.item.CommissionInfo.CommissionBasis }}</div>
                  <div>
                    {{ data.item.CommissionInfo.CommissionPercentage }}%
                  </div>
                </div>
                <div
                  class="text-center text-white"
                  v-if="data.item.CommissionInfo.FeeOnly"
                >
                  <div v-if="data.item.CommissionInfo.FeeOnly">Fee Only</div>
                </div>
                <div
                  class="text-center text-white"
                  v-if="data.item.CommissionInfo.TimeOnly"
                >
                  <div v-if="data.item.CommissionInfo.TimeOnly == 'true'">
                    Time Only
                  </div>
                </div>
                <div class="text-left" v-if="data.item.AddedBillingException">
                  <div class="exception-title">Billing Address</div>
                  <div
                    v-if="
                      data.item.ProductBillingAddress.Country !=
                      CountryUnitedStatesValue
                    "
                    class="text-white exception-body"
                  >
                    <div>
                      {{ data.item.ProductBillingAddress.AddressLine1 }}
                    </div>
                    <div>
                      {{ data.item.ProductBillingAddress.AddressLine2 }}
                    </div>
                  </div>
                  <div v-else class="text-white exception-body">
                    <div>
                      {{ data.item.ProductBillingAddress.StreetAddress }}
                    </div>
                    <div>
                      {{ data.item.ProductBillingAddress.City }},{{
                        data.item.ProductBillingAddress.StateName
                      }},{{ data.item.ProductBillingAddress.ZipCode }}
                    </div>
                  </div>
                </div>
              </b-popover>
            </span>
          </div>
          <div v-else>__</div>
        </template>
        <template slot="custom-foot">
          <b-tr>
            <b-td colspan="2">
              <button
                class="btn btn-link add-product-row text-left"
                @click="AddAnotherProduct()"
              >
                <img :src="addIcon" alt="add" /> Add another row
              </button>
            </b-td>
            <b-td
              colspan="3"
              v-if="IsPaginationRequired"
              style="padding-top: 14px !important"
            >
              <Pagination
                style="float: right"
                aria-controls="open-items"
                v-model="currentPage"
                :total-rows="TotalRows"
                :per-page="perPage"
                first-number
                last-number
              />
            </b-td>
          </b-tr>
        </template>
      </b-table>
      <b-modal
        no-enforce-focus
        v-model="ShowAddBillingCommissionException"
        :no-close-on-backdrop="true"
        scrollable
        title="Add Product"
      >
        <template v-slot:modal-header="{ close }">
          <h5 class="modal-title">Add Exception</h5>
          <button class="close" @click="CancelAddException()"></button>
        </template>
        <div class="d-block row col-lg-10 offset-lg-1 primary-content"></div>
        <b-card
          class="secondary-card ml-5 mr-5"
          v-if="ProductDetails.AddedBillingException == true"
        >
          <span class="exception-close">
            <b-img
              :src="closeIcon"
              @click="DeleteProductBillingExceptionSection()"
              class="Custom-Delete delete-exception"
              title="Delete billing address exception"
            >
            </b-img>
          </span>
          <div class="col-lg-12">
            <b-form-group
              cols="12"
              id="product-modal-billtoname"
              label="Product Bill To Name"
              label-for="billtoname"
              :required="false"
            >
              <b-form-input
                v-model="ProductDetails.ProductBillingAddress.BillToName"
                placeholder="Product Bill To Name"
                :maxlength="$v.ProductDetails.ProductBillingAddress.BillToName.$params.maxLength.max"
                id="billtoname"
              ></b-form-input>
            </b-form-group>
            <div class="form-group custom-dropdown">
              <OmniSelect
                placeholder="Select Country"
                id="country"
                name="Country"
                :showLabel="true"
                label="Select Country"
                v-model="ProductDetails.ProductBillingAddress.Country"
                :options="CountryList"
              ></OmniSelect>
            </div>
            <div
              class="form-group"
              v-if="ProductDetails.ProductBillingAddress.Country == null"
            >
              <p
                class="align-self-center empty-table-text unselected-default-text"
              >
                Select a country to populate the address form
              </p>
            </div>
            <div class="pt-2">
              <b-form-group
                cols="12"
                v-if="IsCountryUSA"
                id="product-modal-Address"
                label="Client Billing Street Address"
                label-for="street-address"
              >
                <b-form-input
                  class=""
                  autocomplete="off"
                  id="street-address"
                  v-model="ProductDetails.ProductBillingAddress.StreetAddress"
                  placeholder="Enter Client Billing Street Address"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                cols="12"
                v-if="IsCountryUSA == false"
                id="product-modal-Address1"
                label="Client Billing Street Address"
                label-for="address1"
              >
                <b-form-input
                  v-model="ProductDetails.ProductBillingAddress.AddressLine1"
                  v-if="IsCountryUSA == false"
                  placeholder="Address Line 1"
                  :maxlength="26"
                  autocomplete="off"
                  id="address1"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                cols="12"
                v-if="IsCountryUSA == false"
                id="product-modal-Address2"
                label-for="address2"
              >
                <b-form-input
                  v-model="ProductDetails.ProductBillingAddress.AddressLine2"
                  autocomplete="off"
                  class="mt-3"
                  v-if="IsCountryUSA == false"
                  placeholder="Address Line 2"
                  :maxlength="26"
                  id="address2"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="form-group custom-dropdown-bottom" v-if="IsCountryUSA">
              <b-form-group label-for="city" label="City">
                <b-form-input
                  autocomplete="off"
                  v-model="ProductDetails.ProductBillingAddress.City"
                  id="city"
                  placeholder="Enter City"
                  :maxlength="
                    $v.ProductDetails.ProductBillingAddress.City.$params
                      .maxLength.max
                  "
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="row" v-if="IsCountryUSA">
              <div class="col-lg-6 custom-dropdown">
                <OmniSelect
                  class="pt-0"
                  placeholder="Select State"
                  name="States"
                  :showLabel="true"
                  :showSearch="true"
                  label="Select State"
                  v-model="ProductDetails.ProductBillingAddress.State"
                  :options="Stateilst"
                ></OmniSelect>
              </div>
              <div class="col-lg-6 ml-0 mt-2">
                <b-form-group
                  label-for="zip-code"
                  v-if="IsCountryUSA"
                  label="Zip Code"
                >
                  <b-form-input
                    @blur="
                      $v.ProductDetails.ProductBillingAddress.ZipCode.$touch()
                    "
                    @focus="ResetZipCodeDirty()"
                    autocomplete="off"
                    :class="{
                      'validation-status-error':
                        $v.ProductDetails.ProductBillingAddress.ZipCode.$invalid && $v.ProductDetails.ProductBillingAddress.ZipCode.$dirty,
                    }"
                    v-model="ProductDetails.ProductBillingAddress.ZipCode"
                    id="zip-code"
                    placeholder="Enter Zip Code"
                    :maxlength="
                      $v.ProductDetails.ProductBillingAddress.ZipCode.$params
                        .maxLength.max
                    "
                  ></b-form-input>
                  <div
                    v-show="
                      !$v.ProductDetails.ProductBillingAddress.ZipCode.minLength && $v.ProductDetails.ProductBillingAddress.ZipCode.$dirty
                    "
                    class="error-message-format"
                  >
                    Input must be 5 characters
                  </div>
                  <div
                    v-show="
                      !$v.ProductDetails.ProductBillingAddress.ZipCode.numeric
                      && $v.ProductDetails.ProductBillingAddress.ZipCode.$dirty
                    "
                    class="error-message-format"
                  >
                    This field can only contain numerical values
                  </div>
                </b-form-group>
              </div>
            </div>
            <b-form-group
              cols="12"
              id="product-modal-attn"
              label="Attn"
              label-for="attn"
              :required="false"
            >
              <b-form-input
                v-model="ProductDetails.ProductBillingAddress.Attn"
                placeholder="Attn"
                :maxlength="$v.ProductDetails.ProductBillingAddress.Attn.$params.maxLength.max"
                id="attn"
              ></b-form-input>
            </b-form-group>
            <br />
            <br />

            <div v-if="showtrucatedbilling">
              <!-- <span>Billing Address Changes</span> -->
              <span class="SUGGESTED-CLIENT-NAM">
                SUGGESTED STREET ADDRESS
              </span>
              <br />
              <span class="Due-to-character-res">
                Due to character restrictions, we suggest the use of the
                following address:
              </span>
              <br />
              <b-form-group
                cols="12"
                v-if="IsCountryUSA"
                id="product-modal-Address"
                label="Client Billing Street Address"
                label-for="street-address"
                style="margin-top: 20px"
              >
                <b-form-input
                  class=""
                  autocomplete="off"
                  id="street-address"
                  v-model="
                    ProductDetails.ProductBillingAddress
                      .TruncatedBillingStreetAddress
                  "
                  placeholder="Enter Client Billing Street Address"
                  :maxlength="26"
                ></b-form-input>
              </b-form-group>
              <div
                class="form-group custom-dropdown-bottom"
                v-if="IsCountryUSA"
              >
                <b-form-group label-for="city" label="City">
                  <b-form-input
                    autocomplete="off"
                    v-model="ProductDetails.ProductBillingAddress.City"
                    id="city"
                    placeholder="Enter City"
                    :maxlength="
                      $v.ProductDetails.ProductBillingAddress.City.$params
                        .maxLength.max
                    "
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="row" v-if="IsCountryUSA">
                <div class="col-lg-6 custom-dropdown">
                  <OmniSelect
                    class="pt-0"
                    placeholder="Select State"
                    name="States"
                    :showLabel="true"
                    :showSearch="true"
                    label="Select State"
                    v-model="ProductDetails.ProductBillingAddress.State"
                    :options="Stateilst"
                  ></OmniSelect>
                </div>
                <div class="col-lg-6 ml-0 mt-2">
                  <b-form-group
                    label-for="zip-code"
                    v-if="IsCountryUSA"
                    label="Zip Code"
                  >
                    <b-form-input
                      @change="
                        $v.ProductDetails.ProductBillingAddress.ZipCode.$touch()
                      "
                      autocomplete="off"
                      :class="{
                        'validation-status-error':
                          $v.ProductDetails.ProductBillingAddress.ZipCode
                            .$error,
                      }"
                      v-model="ProductDetails.ProductBillingAddress.ZipCode"
                      id="zip-code"
                      placeholder="Enter Zip Code"
                      :maxlength="
                        $v.ProductDetails.ProductBillingAddress.ZipCode.$params
                          .maxLength.max
                      "
                    ></b-form-input>
                    <p
                      v-show="
                        !$v.ProductDetails.ProductBillingAddress.ZipCode
                          .minLength
                      "
                      class="validation-status-error-message"
                    >
                      Minimum 5 characters required.
                    </p>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
        </b-card>
        <div class="ml-5 mr-5">
          <button
            class="btn btn-link add-product-row text-left"
            v-if="ProductDetails.AddedBillingException != true"
            @click="AddProductBillingExceptionSection()"
          >
            <img :src="addIcon" alt="add" /> Add Billing Exception
          </button>
          <button
            class="btn btn-link add-product-row text-left"
            v-if="ProductDetails.AddedCommissionException != true"
            @click="AddProductCommissionExceptionSection()"
          >
            <img :src="addIcon" alt="add" /> Add Commission Exception
          </button>
        </div>
        <b-card
          class="secondary-card commission-exception ml-5 mr-5"
          v-if="ProductDetails.AddedCommissionException == true"
        >
          <b-img
            :src="closeIcon"
            @click="DeleteProductCommissionExceptionSection()"
            class="Custom-Delete delete-exception"
            title="Delete commission exception"
          >
          </b-img>
          <b-row class="ml-1 mr-1 mt-2">
            <b-form-group
              class="timeonly-text"
              label="Is this request Time Only ?"
            >
              <b-form-radio-group
                id="time-only"
                v-model="ProductDetails.CommissionInfo.TimeOnly"
                name="time-only"
              >
                <b-form-radio value="true">Yes</b-form-radio>
                <b-form-radio value="false">No</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-row>
          <b-row class="ml-1 mr-1 mt-2">
            <b-form-group
              label="Please select if this client will be billed as a fee or on a commission basis. If commission basis, please select Net or Gross:"
              v-if="ProductDetails.CommissionInfo.TimeOnly != 'true'"
            >
              <b-form-radio
                class="inline-custom-radio-commission"
                v-model="ProductDetails.CommissionInfo.FeeOnly"
                name="CommissionBasis-modal"
                value="Yes"
                >Fee Only</b-form-radio
              >
              <b-form-radio
                class="inline-custom-radio-commission"
                v-model="ProductDetails.CommissionInfo.CommissionBasis"
                name="CommissionBasis-modal"
                value="Net"
                >Net</b-form-radio
              >
              <b-form-radio
                class="inline-custom-radio-commission"
                v-model="ProductDetails.CommissionInfo.CommissionBasis"
                name="CommissionBasis-modal"
                value="Gross"
                >Gross</b-form-radio
              >
            </b-form-group>
          </b-row>
          <div
            class="mt-2 ml-1 mr-1"
            v-if="
              ProductDetails.CommissionInfo.TimeOnly != 'true' &&
              (ProductDetails.CommissionInfo.CommissionBasis == 'Net' ||
                ProductDetails.CommissionInfo.CommissionBasis == 'Gross')
            "
          >
            <b-form-group
              id="product-modal-commission-percentage"
              label="Commission %"
              label-for="commision-percentage"
            >
              <b-form-input
                autocomplete="off"
                id="commision-percentage"
                v-numericWithDecimal
                v-model="ProductDetails.CommissionInfo.CommissionPercentage"
                placeholder="Enter Commission %"
                :class="{
                  'validation-status-error':
                    !$v.ProductDetails.CommissionInfo.CommissionPercentage
                      .decimal
                      && $v.ProductDetails.CommissionInfo.CommissionPercentage
                      .$dirty,
                }"
                @blur="
                  $v.ProductDetails.CommissionInfo.CommissionPercentage.$touch()
                "
                @focus="ResetCommissionDirty()"
              ></b-form-input>
              <div
                v-show="
                  !$v.ProductDetails.CommissionInfo.CommissionPercentage.decimal
                  && $v.ProductDetails.CommissionInfo.CommissionPercentage
                      .$dirty
                "
                class="error-message-format"
              >
                Please enter valid decimal number. If .(dot) is entered add at
                least one number after that.
              </div>
            </b-form-group>
          </div>
          <div
            class="panel-list media-dropdowns ml-1 mr-1"
            v-if="ProductDetails.CommissionInfo.TimeOnly != 'true'&& !IsCommissionProductionBilling"
          >
            <OmniSelect
              :options="NETList"
              v-model="SelectedNETList"
              v-show="IsAllNetMediaNotSelected"
              name="NET"
              placeholder="Select Net Media"
              :showSearch="true"
              :showSelectAll="true"
              selectAllText="All"
              unSelectAllText="None"
              multiple="true"
            />
            <OmniSelect
              :options="SPOTList"
              v-model="SelectedSPOTList"
              name="SPOT"
              v-show="IsAllSpotMediaNotSelected"
              placeholder="Select Spot Media"
              :showSearch="true"
              :showSelectAll="true"
              selectAllText="All"
              unSelectAllText="None"
              multiple="true"
            />
            <OmniSelect
              :options="PRINTList"
              v-model="SelectedPRINTList"
              v-show="IsAllPrintMediaNotSelected"
              name="PRINT"
              placeholder="Select Print Media"
              :showSearch="true"
              :showSelectAll="true"
              selectAllText="All"
              unSelectAllText="None"
              multiple="true"
            />
          </div>
        </b-card>
        <template v-slot:modal-footer="{ ok, cancel }">
          <div class="text-right">
            <button
              class="btn btn-link text-uppercase"
              @click="CancelAddException()"
            >
              Cancel
            </button>
            <button
              :disabled="$v.$invalid || !SelectedMedia"
              class="text-uppercase btn custom-button btn-primary"
              @click="AddProductBillingCommissionException()"
            >
              Add
            </button>
            <!-- <button
              v-if="!IsAddModal"
              :disabled="$v.$invalid || !SelectedMedia"
              class="text-uppercase btn custom-button btn-primary"
              @click="SaveProductException()"
            >
              Save
            </button> -->
          </div>
        </template>
      </b-modal>
      <b-modal v-model="ShowDelete" hide-footer :no-close-on-backdrop="true">
        <div>
          <p>
            This action will permanently delete this product. This cannot be
            undone.
          </p>
        </div>
        <template v-slot:modal-header="{ close }">
          <h5 class="modal-title">{{ DeleteProductTitle }}</h5>
          <button class="close" @click="CancelDelete()"></button>
        </template>

        <div class="text-right">
          <button class="btn btn-link text-uppercase" @click="CancelDelete()">
            Cancel
          </button>
          <button
            class="text-uppercase btn custom-button btn-primary"
            @click="DeleteConfirm()"
          >
            Delete
          </button>
        </div>
      </b-modal>
      <b-modal v-model="ShowExcelUpload" :no-close-on-backdrop="true">
        <template v-slot:modal-header="{ close }">
          <h5 class="modal-title">Excel Upload</h5>
          <button class="close" @click="CancelExcelUpload()"></button>
        </template>
        <div>
          <b-card class="products-upload-card">
            <section class="excel-file-upload-steps">
              <label>How to upload excel file for product table:</label>
              <ol>
                <li>
                  <!-- <xlsx-workbook>
                    <xlsx-sheet
                      :collection="sheet.data"
                      v-for="sheet in sheets"
                      :key="sheet.name"
                      :sheet-name="sheet.name"
                      :comments="sheet.comments"
                    />
                    <xlsx-download :filename="'FinancialSystemsProducts.xlsx'" :options="downloadOptions">
                      <button>Download this template</button>
                    </xlsx-download>
                  </xlsx-workbook> -->
                  <a @click="downloadProductsUploadTemplate()"
                    >Download this template</a
                  >
                </li>
                <li>Add your data to the template file</li>
                <li>Upload it below</li>
              </ol>
            </section>
          </b-card>
          <div class="mt-4">
            <div class="col-lg-10 mx-auto">
              <label class="excel-upload-label">Excel Upload</label>
              <vue-dropzone
                :duplicateCheck="true"
                ref="excelUploadDropzone"
                id="dropzone"
                :useCustomSlot="true"
                class="customdropzone"
                :include-styling="false"
                :options="dropzoneOptions"
              >
                <div class="dropzone-custom-content">
                  <p class="drag-and-drop-file-h">
                    <b-img
                      :src="closeFilterIcon"
                      style="
                        margin-top: -7px !important;
                        font-family: 'Open Sans';
                      "
                    />
                    Drag and drop file here or
                    <span class="draganddropspan"> browse </span>
                  </p>
                </div>
              </vue-dropzone>
            </div>
          </div>
        </div>
        <template v-slot:modal-footer="{ ok, cancel }">
          <div class="text-right">
            <button
              class="btn btn-link text-uppercase"
              @click="CancelExcelUpload()"
            >
              Cancel
            </button>
            <button
              class="text-uppercase btn custom-button btn-primary"
              @click="AddProductsFromExcel()"
            >
              Add
            </button>
          </div>
        </template>
      </b-modal>
    </b-card>
    <b-form-group class="text-right footer-buttons">
      <button class="btn custom-button btn-link" @click="Cancel()">
        Cancel
      </button>
      <button
        class="text-uppercase btn btn-secondary btn-primary"
        @click="SaveasDraft()"
      >
        SAVE FOR LATER
      </button>

      <button class="btn custom-button btn-primary" @click="Previous()">
        BACK
      </button>
      <button
        class="btn custom-button btn-primary"
        :disabled="$v.$invalid || IsDuplicateProductCodes"
        @click="Next()"
      >
        Next
      </button>

    </b-form-group>
  </div>
</template>

<script>
import store from "@/store";
import {
  requiredIf,
  maxLength,
  minLength,
  required,
  numeric
} from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import service from "../../services/Dropdowns";
import { NETMedia, PRINTMedia, SPOTMedia } from "../../utilities/MediaList";
import readXlsxFile from "read-excel-file";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
// import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx";
export default {
  validations: {
    ClientProductList: {
      required,
      minLength: minLength(1),
      $each: {
        ProductName: {
          required: requiredIf(function () {
            return this.ShowAddBillingCommissionException != true;
          }),
          maxLength: maxLength(20),
        },
        ProductCode: {
          required: requiredIf(function () {
            return this.ShowAddBillingCommissionException != true;
          }),
          maxLength: maxLength(3),
          minLength: minLength(2),
          unique(value) {
          return (
            !this.ClientProductList ||
             value == null || value.length == 0 ||(this.ClientProductList.filter((val) => val.ProductCode == value)
                  .length < 2)
          );
        },
        },
      },
    },
    ProductDetails: {
      // ProductName: {
      //   required: requiredIf(function () {
      //     return this.ShowAddProduct;
      //   }),
      // },
      // ProductCode: {
      //   required: requiredIf(function () {
      //     return this.ShowAddProduct;
      //   }),
      //   maxLength: maxLength(3),
      //   minLength: minLength(2),
      //   unique(value) {
      //     return (
      //       !this.ClientProductList ||
      //       (this.EditIndex > -1
      //         ? this.ClientProductList.filter(
      //             (val, index) =>
      //               val.ProductCode == value && index != this.EditIndex
      //           ).length < 1
      //         : this.ClientProductList.filter((val) => val.ProductCode == value)
      //             .length < 1)
      //     );
      //   },
      // },
      CommissionInfo: {
        FeeOnly: {
          required: requiredIf(function (model) {
            return (
              this.ShowAddBillingCommissionException == true &&
              this.ProductDetails.AddedCommissionException &&
              model.CommissionBasis == null &&
              model.TimeOnly != "true"
            );
          }),
        },
        CommissionBasis: {
          required: requiredIf(function (model) {
            return (
              this.ShowAddBillingCommissionException &&
              this.ProductDetails.AddedCommissionException &&
              model.FeeOnly == null &&
              model.TimeOnly != "true"
            );
          }),
        },
        CommissionPercentage: {
          decimal(commission, model) {
            return this.ShowAddBillingCommissionException &&
              this.ProductDetails.AddedCommissionException &&
              (model.CommissionBasis == "Net" ||
                model.CommissionBasis == "Gross")
              ? /^([0-9]*|[0-9]*.[0-9][0-9]*)$/.test(commission)
              : true;
          },
          required: requiredIf(function (model) {
            return (
              this.ShowAddBillingCommissionException &&
              this.ProductDetails.AddedCommissionException &&
              (model.CommissionBasis == "Net" ||
                model.CommissionBasis == "Gross")
            );
          }),
        },
      },
      ProductBillingAddress: {
        TruncatedProductName: "",
        Attn:{
         maxLength:maxLength(10)
        },
        BillToName: {
          required: requiredIf(function () {
            return (
              this.ShowAddBillingCommissionException &&
              this.ProductDetails.AddedBillingException
            );
          }),
          maxLength:maxLength(20)
        },
        Country: {
          required: requiredIf(function () {
            return (
              this.ShowAddBillingCommissionException &&
              this.ProductDetails.AddedBillingException
            );
          }),
        },
        AddressLine1: {
          required: requiredIf(function (model) {
            return (
              this.ShowAddBillingCommissionException &&
              this.ProductDetails.AddedBillingException &&
              model.Country != this.CountryUnitedStatesValue
            );
          }),
        },
        AddressLine2: {
          required: requiredIf(function (model) {
            return (
              this.ShowAddBillingCommissionException &&
              this.ProductDetails.AddedBillingException &&
              model.Country != this.CountryUnitedStatesValue
            );
          }),
        },
        City: {
          required: requiredIf(function (model) {
            return (
              this.ShowAddBillingCommissionException &&
              this.ProductDetails.AddedBillingException &&
              model.Country == this.CountryUnitedStatesValue
            );
          }),
          maxLength: maxLength(30),
        },
        ZipCode: {
          required: requiredIf(function (model) {
            return (
              this.ShowAddBillingCommissionException &&
              this.ProductDetails.AddedBillingException &&
              model.Country == this.CountryUnitedStatesValue
            );
          }),
          numeric,
          maxLength: maxLength(5),
          minLength: minLength(5),
        },
        State: {
          required: requiredIf(function (model) {
            return (
              this.ShowAddBillingCommissionException &&
              this.ProductDetails.AddedBillingException &&
              model.Country == this.CountryUnitedStatesValue
            );
          }),
        },
        StreetAddress: {
          required: requiredIf(function (model) {
            return (
              this.ShowAddBillingCommissionException &&
              this.ProductDetails.AddedBillingException &&
              model.Country == this.CountryUnitedStatesValue
            );
          }),
          //maxLength: maxLength(26)
        },

        TruncatedBillingStreetAddress: {
          required: requiredIf(function () {
            return this.showtrucatedbilling == true;
          }),
          maxLength: maxLength(26),
        },
      },
    },
  },
  components: {
    vueDropzone: vue2Dropzone,
    // XlsxWorkbook,
    // XlsxSheet,
    // XlsxDownload,
  },
  data() {
    return {

      productBillingAddress : {},
      productMediaDetails : [],
      commissionInfo :[],
      ProductInfo:[],
      clientForm:{},
      currentPage: 1,
      perPage: 10,
      sheets: [
        {
          name: "Products",
          data: [{ "Product Name": "", "Product Code": "" }],
          components: [{ "Product Name": "test", "Product Code": "test" }],
        },
      ],
      ShowExcelUpload: false,
      dropzoneOptions: {
        init: function () {
          this.on("maxfilesexceeded", function (file) {
            this.removeFile(file);
            alert("Maximum File Upload limit is 1!");
          });
        }, // paramName1: filecount,
        acceptedFiles: ".xlsx",
        paramName: "file",
        //params: {'param_1':'xyz','para_2': localid },
        previewTemplate: this.template(),
        url: `${process.env.VUE_APP_API_ROOT}api/v1/test/`,
        uploadMultiple: false,
        maxFiles: 1,
        minFiles: 1,
        maxFilesize: 10,
        addRemoveLinks: false,
        parallelUploads: 1,
        createImageThumbnails: false,
        autoProcessQueue: false,
      },
      clientbillingstreetaddresslength: null,
      showtrucatedbilling: false,
      showtruncated: false,
      showtruncationvalidationmessage: false,
      truncatedmaxlength: 20,
      addIcon: require("@/assets/Icons/add.svg"),
      deleteIcon: require("@/assets/Icons/delete.svg"),
      closeIcon: require("@/assets/Icons/closeicon.svg"),
      editIcon: require("@/assets/Icons/edit.svg"),
      ModalTitle: "Add Product",
      ShowAddExceptionsPopover: false,
      ProductDetails: null,
      ShowAddProduct: false,
      ShowAddBillingCommissionException: false,
      ShowBillingException: false,
      ShowCommissionException: false,
      CommissionBasisOptions: [
        { text: "NET", value: "Net" },
        { text: "GROSS", value: "Gross" },
      ],
      CountryList: [],
      Stateilst: [],
      NETList: [],
      SPOTList: [],
      PRINTList: [],
      SelectedNETList: [],
      SelectedPRINTList: [],
      SelectedSPOTList: [],
      Country: null,
      fields: [
        { key: "Actions", label: "", sortable: false },
        { key: "ProductName", label: "Product Name", sortable: true },
        { key: "ProductCode", label: "Product Code", sortable: true },
        // { key: "Media", label: "Media", sortable: true },
        // { key: "ProductExceptions", label: "Exceptions", sortable: true },
        {
          key: "ProductBillingAddress",
          label: "Address Exception",
          sortable: true,
        },
        {
          key: "CommissionInfo",
          label: "Commission Exception",
          sortable: true,
        },
      ],
      ShowDelete: false,
      DeleteProductTitle: null,
      DeleteIndex: -1,
      EditIndex: -1,
      CountryUnitedStatesValue: "78d70193e1eb493dafcf3f261b7759ef",
      NetMedia: "NET",
      PrintMedia: "PRINT",
      SpotMedia: "SPOT",
    };
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
    isDraft:{
        type:Boolean,
        required:false
      }
  },
  mounted() {
    this.$parent.$emit("stepInitiated", this.$route.meta.tabIndex);
  },
  activated() {
    this.$parent.$emit("stepInitiated", this.$route.meta.tabIndex);
  },
  created() {
    this.$parent.$emit("stepInitiated", this.$route.meta.tabIndex);
    this.InitiateObject();
    this.LoadDropdownValues();
    if (this.id) {
      this.LoadDropdownValueNames();
    }
    if(this.id==0 || this.id<1 ||isNaN(this.id)){
        this.ResetTable();
    }

  },
  watch: {
    "ProductDetails.CommissionInfo.TimeOnly": function (newVal) {
      if (newVal == "true") {
        this.ResetCommissionMedia(newVal);
      }
    },
    "ProductDetails.CommissionInfo.FeeOnly": function (newVal) {
      this.ResetCommissionBasis(newVal);
    },
    "ProductDetails.CommissionInfo.CommissionBasis": function (newVal) {
      this.ResetFeeOnly(newVal);
    },

    "ProductDetails.ProductBillingAddress.StreetAddress": function (newVal) {
      if (newVal!=null && newVal.length > 26) {
        this.showtrucatedbilling = true;
        //TruncatedBillingStreetAddress
        this.ProductDetails.ProductBillingAddress.TruncatedBillingStreetAddress = this.ProductDetails.ProductBillingAddress.StreetAddress.slice(
          0,
          25
        );
      } else {
        this.ProductDetails.ProductBillingAddress.TruncatedBillingStreetAddress = null;
        this.showtrucatedbilling = false;
      }
    },
  },
  computed: {
    IsDuplicateProductCodes() {
      if (this.ClientProductList != null && this.ClientProductList.length > 0) {
        var valueArr = this.ClientProductList.map(function (item) {
          return item.ProductCode;
        });
        var isDuplicate = valueArr.some(function (item, idx) {
          return valueArr.indexOf(item) != idx;
        });
        return isDuplicate;
      } else {
        return false;
      }
    },
    DuplicateProductCodes() {
      if (this.ClientProductList != null && this.ClientProductList.length > 0) {
        var input = this.ClientProductList.map(function (item) {
          return item.ProductCode;
        });
        var duplicates = input.reduce(function (acc, el, i, arr) {
          if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push(el);
          return acc;
        }, []);
        return duplicates;
      } else {
        return [];
      }
    },
    BillingPartyNotTimeOnly() {
      let timeOnlyOptions = [
        "Time Only (Client Related Time MED)",
        "Time Only (Internal Project Related Time ADM)",
      ];
      //let timeOnlySelected=timeOnlyOptions.includes(this.BillingInfo.BillingParty);
      return !(
        this.BillingInfo &&
        timeOnlyOptions.includes(this.BillingInfo.BillingParty)
      );
    },
    TotalRows() {
      return this.ClientProductList != null
        ? parseInt(this.ClientProductList.length)
        : 0;
    },
    IsPaginationRequired() {
      return (
        this.ClientProductList != null &&
        parseInt(this.TotalRows) > parseInt(this.perPage)
      );
    },
    IsBillingExceptionNotAdded() {
      return (
        this.ShowBillingException != true &&
        this.ProductDetails.AddedBillingException != true
      );
    },
    IsCommissionExceptionNotAdded() {
      return (
        this.ShowCommissionException != true &&
        this.ProductDetails.AddedCommissionException != true
      );
    },
    IsAllNetMediaNotSelected() {
      return this.NETList != null && this.NETList.length > 0;
    },
    IsAllSpotMediaNotSelected() {
      return this.SPOTList != null && this.SPOTList.length > 0;
    },
    IsAllPrintMediaNotSelected() {
      return this.PRINTList != null && this.PRINTList.length > 0;
    },
    SelectedMediaFromCommission() {
      let media = [];
      if (this.CommissionInfo != null && this.CommissionInfo.length > 0) {
        this.CommissionInfo.forEach((s) => {
          s.Media.forEach((mediaType) => {
            media.push(mediaType.MediaType);
          });
        });
      }
      return media;
    },
    IsCommissionProductionBilling(){
      return this.ClientBasicDetails!=null && this.ClientBasicDetails.ProductionBilling;
    },
    ...mapGetters("client", {
      CommissionInfo: "clientCommissionInfo",
    }),
    ...mapGetters("client", {
      BillingInfo: "ClientBillingInformations",
    }),
    ...mapGetters("client",{
    ClientBasicDetails:"ClientBasicDetails"
    }),

    ...mapGetters("client",{
      clientinfopageone: "ClientBasicDetails",
      clientinfopagetwo: "ClientEularInfo",
      clientinfopagethree: "ClientBillingInformations",
      CommissionInfo: "clientCommissionInfo",
      ClientCommissionInfo: "clientCommissionInfo",
      PaymentTerms: "PaymentTerms",
      ClientProductList: "clientProductList",
      BillingDefaultPaymentTerms:"ClientBillingInformationsDefaultPaymentTerms",
    }),






    SelectedMedia() {
      return (
        !this.ProductDetails.AddedCommissionException ||
        this.IsMediaUsed(this.SelectedNETList) ||
        this.IsMediaUsed(this.SelectedPRINTList) ||
        this.IsMediaUsed(this.SelectedSPOTList) ||
        this.TimeOnlySelected()||
        this.IsCommissionProductionBilling
      );
    },
    IsCountryUSA() {
      return this.ProductDetails.ProductBillingAddress.Country != null
        ? this.ProductDetails.ProductBillingAddress.Country ==
            "78d70193e1eb493dafcf3f261b7759ef"
        : null;
    },
    ...mapGetters("client", {
      ClientProductList: "clientProductList",
    }),
    IsAddModal() {
      return this.EditIndex < 0;
    },
  },
  methods: {
    ResetListDirty(index,prop){
     this.$v.ClientProductList.$each.$iter[this.GetRowIndex(index)][prop].$reset();
    },
     ResetDirty(prop){
     this.$v[prop].$dirty=false;
    },
    ResetZipCodeDirty(){
     this.$v.ProductDetails.ProductBillingAddress.ZipCode.$reset();
    },
    ResetCommissionDirty(){
     this.$v.ProductDetails.CommissionInfo.CommissionPercentage.$reset();
    },
    pasteDataFromClipboard(e) {
      var clipboardData = e.clipboardData;
      if (!clipboardData) {
        //chrome
        clipboardData = e.originalEvent.clipboardData;
      }
      var pastedText =
        clipboardData.getData("Text") || clipboardData.getData("text/plain");

      if (!pastedText && pastedText.length) {
        return;
      }

      // Parse the pasted text from Excel into rows.
      // Pasted text is usually separated by a new line for each row,
      // but a single cell can contain multiple lines, which is what
      // we pars out in the first `replace`.
      //
      // We find all text within double-quotes ('"') which has new
      // lines, and put the text within the quotes into capture
      // groups. For each match, we replace its contents again, by
      // removing the new lines with spaces.
      //
      // Then lastly, once we've joined all the multi line cells, we
      // split the entire pasted content on new lines, which gives
      // us an array of each row.
      //
      // Since Windows usually uses weird line-endings, we need to
      // ensure we check for each of the different possible
      // line-endings in every regexp.
      //
      // It also handles cells which contains quotes. There appears
      // to be two ways this is handled. In Google Docs, quotes within
      // cells are always doubled up when pasting, so " becomes "".
      // In Libre Office, the quotes are not normal quotes, some
      // other character is used, so we don't need to handle it any
      // differently.
      var rows = pastedText
        .replace(
          /"((?:[^"]*(?:\r\n|\n\r|\n|\r))+[^"]+)"/gm,
          function (match, p1) {
            // This function runs for each cell with multi lined text.
            return (
              p1
                // Replace any double double-quotes with a single
                // double-quote
                .replace(/""/g, '"')
                // Replace all new lines with spaces.
                .replace(/\r\n|\n\r|\n|\r/g, " ")
            );
          }
        )
        // Split each line into rows
        .split(/\r\n|\n\r|\n|\r/g);
        window.console.log(`${rows}`);
    },
    async downloadProductsUploadTemplate() {
      let file = { Name: "FinancialSystemsProducts.xlsx" };
      this.$store
        .dispatch("client/DownloadProductsUploadTemplate", file)
        .then((response) => {
          const blob = new Blob([response.data], { type: file.Extension });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = file.Name;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((ex) => {
          if (ex.response) {
            this.$store.dispatch(
              "progress/setErrorMessage",
              `Error while downloading file. ${ex.response.data}`
            );
          } else {
            this.$store.dispatch(
              "progress/setErrorMessage",
              `Error while downloading file. ${ex}`
            );
          }
        });
    },
    DeleteProductBillingExceptionSection() {
      this.DeleteBillingExceptionSection();
    },
    DeleteProductCommissionExceptionSection() {
      this.DeleteCommissionExceptionSection();
    },
    AddProductCommissionExceptionSection() {
      this.ProductDetails.AddedCommissionException = true;
    },
    AddProductBillingExceptionSection() {
      this.ProductDetails.AddedBillingException = true;
    },
    GetRowIndex(index){
 return index+(this.currentPage - 1) * this.perPage;
    },
    ShowBillingCommissionException(index, commission) {
      this.ShowAddBillingCommissionException = true;
      this.EditIndex = index + (this.currentPage - 1) * this.perPage;
      this.LoadProductDetails();
      if (commission == true) {
        this.$set(this.ProductDetails, "AddedCommissionException", true);
      } else {
        this.$set(this.ProductDetails, "AddedBillingException", true);
      }
    },
    CancelAddException() {
      this.ShowAddBillingCommissionException = false;
      this.InitiateObject();
      this.EditIndex = -1;
      this.DeleteCommissionExceptionSection();
      this.DeleteBillingExceptionSection();
    },
    AddProductBillingCommissionException() {
      this.SaveProduct();
      this.ShowAddBillingCommissionException = false;
      this.InitiateObject();
      this.EditIndex = -1;
    },
    CancelExcelUpload() {
      this.ShowExcelUpload = false;
    },
    template: function () {
      return `<div class="dz-preview dz-file-preview" style='background-color:#ffffff !important;display:flex;'>

                <div class="dz-details" style='margin-right:79px !important;'>
                    <div class="dz-size" style="display:none;" ><span data-dz-size></span></div>
                    <div class="FileforNewClientxls-Copy" style="margin-left:20px;width:280px;cursor:default !important;" ><span style='word-break:break-all !important;font-size:14px !important;font-weight:400;font-stretch:normal;font-style: normal;line-height: 0.71;color: #00a1d2 !important; font-family:"Open Sans" !important;' data-dz-name></span></div>
                </div>
                <a>
                 <img data-dz-remove=""  style='' src='/icons/fileremove.svg'  @click="DeleteServerFile()" ></img>
                </a>
            </div>`;
    },

    openFileUpload() {
      this.ShowExcelUpload = true;
      // this.$refs.file.value=null;
      // this.$refs.file.click();
    },
    mapUploadedFileData(rows) {
      if (rows) {
        if (rows.length > 0) {
          rows.forEach((item) => {
            this.$set(this.ProductDetails, "ProductName", item["Product Name"]);
            this.$set(this.ProductDetails, "ProductCode", item["Product Code"]);
            this.AddProductUploadedProduct();
          });
          store.dispatch(
            "progress/setSuccessMessage",
            `${rows.length} product(s) uploaded successfully.`
          );
          if(this.IsDuplicateProductCodes){
            store.dispatch("progress/setErrorMessage", `Duplicate product codes found. Duplicates are ${this.DuplicateProductCodes.join(',')}`);
          }
          this.$v.$touch();
        } else {
          store.dispatch("progress/setErrorMessage", `No product(s) to upload`);
        }
      }
    },
    AddProductsFromExcel() {
      let columns = "";
      const schema = {
        "Product Name": {
          prop: "Product Name",
          type: String,
        },
        "Product Code": {
          prop: "Product Code",
          type: String,
        },
      };
      if (this.$refs.excelUploadDropzone.dropzone.files.length > 0) {
        if (
          this.$refs.excelUploadDropzone.dropzone.files[0].name.indexOf(
            ".xlsx"
          ) > -1
        ) {
          var file = this.$refs.excelUploadDropzone.dropzone.files[0];
          // alert(`${JSON.stringify(this.$refs.file.files.length)}`);
          readXlsxFile(file, {
            schema,
            transformData(data) {
              // // Adds header row to the data.
              // return [['Product Name', 'Product Code']].concat(data);
              // Removes empty rows.
              if (data != null) columns = data.toString();
              return data.filter(
                (row) => row.filter((column) => column !== null && column!='' && column.length>0).length > 0
              );
            },
          }).then(({ rows }) => {
            // `errors` have shape `{ row, column, error, value }`
            if (
              (columns.indexOf("Product Name") > -1 &&
                columns.indexOf("Product Code") > 12) ||
              (columns.indexOf("Product Name") > 12 &&
                columns.indexOf("Product Code") > -1)
            ) {
              this.mapUploadedFileData(rows);
              this.ShowExcelUpload = false;
            } else {
              store.dispatch(
                "progress/setErrorMessage",
                `File format is not valid, it should contain 'Product Name' and 'Product Code' columns.`
              );
            }
          });
        } else {
          store.dispatch(
            "progress/setErrorMessage",
            `Only .xlsx file types are allowed`
          );
        }
      } else {
        store.dispatch(
          "progress/setErrorMessage",
          `Please select .xlsx file to upload`
        );
      }
    },
    DeleteCommissionExceptionSection() {
      this.ShowCommissionException = false;
      this.$set(this.ProductDetails.CommissionInfo, "FeeOnly", null);
      this.$set(this.ProductDetails.CommissionInfo, "TimeOnly", null);
      this.$set(
        this.ProductDetails.CommissionInfo,
        "CommissionPercentage",
        null
      );
      this.$set(this.ProductDetails.CommissionInfo, "CommissionBasis", null);
      this.$set(this.ProductDetails, "AddedCommissionException", false);
      this.$set(this.ProductDetails, "Media", []);
      this.ResetCommissionMedia();
    },
    DeleteBillingExceptionSection() {
      this.ShowBillingException = false;
      this.$set(this.ProductDetails, "AddedBillingException", false);
      this.$set(this.ProductDetails.ProductBillingAddress, "Country", null);
      this.$set(this.ProductDetails.ProductBillingAddress, "CountryName", null);
      this.$set(this.ProductDetails.ProductBillingAddress, "City", null);
      this.$set(this.ProductDetails.ProductBillingAddress, "State", null);
      this.$set(this.ProductDetails.ProductBillingAddress, "StateName", null);
      this.$set(this.ProductDetails.ProductBillingAddress, "ZipCode", null);
      this.$set(
        this.ProductDetails.ProductBillingAddress,
        "AddressLine1",
        null
      );
      this.$set(
        this.ProductDetails.ProductBillingAddress,
        "AddressLine2",
        null
      );
      this.$set(
        this.ProductDetails.ProductBillingAddress,
        "StreetAddress",
        null
      );
      this.$set(this.ProductDetails.ProductBillingAddress, "Attn", null);
    },
    LoadDropdownValueNames() {
      if (
        this.id &&
        this.ClientProductList != null &&
        this.ClientProductList.length > 0
      ) {
        this.ClientProductList.forEach((prod) => {
          this.AssignDropdownNames(prod);
        });
      }
    },
    TimeOnlySelected() {
      return (
        this.BillingPartyTimeOnly ||
        this.ProductDetails.CommissionInfo.TimeOnly == "true"
      );
    },
    IsMediaSelected(list) {
      let selectedMedia = -1;
      selectedMedia = list.findIndex((obj) => {
        return obj.Selected;
      });
      if (selectedMedia > -1) {
        return true;
      }
    },
    IsMediaUsed(list) {
      return list != null && list.length > 0;
    },
    LoadDropdownValues() {
      service
        .getEntityRecords("States")
        .then((res) => {
          this.Stateilst = res.data;
        })
        .catch((err) => {
          alert(err);
        });

      service
        .getEntityRecords("Markets")
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.CountryList = res.data.sort((a, b) =>
              a.label < b.label ? -1 : a.label > b.label ? 1 : 0
            );
            let indexUSA = this.CountryList.findIndex(
              (i) => i.label == "USA - United States"
            );
            let indexCanada = this.CountryList.findIndex(
              (i) => i.label == "CAN - Canada"
            );
            if (indexCanada > -1)
              this.CountryList.unshift(
                this.CountryList.splice(indexCanada, 1)[0]
              );
            if (indexUSA > -1)
              this.CountryList.unshift(this.CountryList.splice(indexUSA, 1)[0]);
          }
        })
        .catch((err) => {
          alert(err);
        });
    },

    handleBlur() {
      this.showtruncationvalidationmessage = false;
    },

    remaincharCount: function () {
      if (this.ProductDetails.ProductName.length > this.truncatedmaxlength) {
        this.showtruncated = true;
        this.showtruncationvalidationmessage = true;
        this.ProductDetails.TruncatedProductName = this.ProductDetails.ProductName.slice(
          0,
          20
        );
      } else {
        this.showtruncated = false;
        this.showtruncationvalidationmessage = false;
        this.ProductDetails.TruncatedProductName = "";
      }
    },

    ResetCommissionBasis(value) {
      if (value) {
        this.ProductDetails.CommissionInfo.CommissionBasis = null;
        this.ProductDetails.CommissionInfo.CommissionPercentage = null;
        this.ProductDetails.CommissionInfo.TimeOnly = "false";
      }
    },

    ResetFeeOnly(value) {
      if (value) {
        this.ProductDetails.CommissionInfo.FeeOnly = null;
        this.ProductDetails.CommissionInfo.TimeOnly = "false";
      }
    },
    ResetCommissionMedia() {
      this.ProductDetails.CommissionInfo.FeeOnly = null;
      this.ProductDetails.CommissionInfo.CommissionBasis = null;
      this.ProductDetails.CommissionInfo.CommissionPercentage = null;
      this.SelectedNETList = [];
      this.SelectedSPOTList = [];
      this.SelectedPRINTList = [];
    },

    DisableUnselectedMedia(mediaType) {
      for (let i = 0; i < mediaType.length; i++) {
        if (
          !this.SelectedMediaFromCommission.includes(mediaType[i].MediaType)
        ) {
          this.$set(mediaType[i], "Disabled", true);
        }
      }
    },
    DeleteUnselectedMedia(mediaType) {
      for (var i = mediaType.length - 1; i >= 0; i--) {
        if (
          !this.SelectedMediaFromCommission.includes(mediaType[i].MediaType)
        ) {
          this.$delete(mediaType, i);
        }
      }
    },
    RenitializeMediaTypes() {
      this.DeleteUnselectedMedia(this.NETList);
      this.DeleteUnselectedMedia(this.SPOTList);
      this.DeleteUnselectedMedia(this.PRINTList);
    },
    InitiateObject() {
      this.ReloadNETMedia();
      this.ReloadSPOTMedia();
      this.ReloadPRINTMedia();
      this.SelectedNETList = [];
      this.SelectedSPOTList = [];
      this.SelectedPRINTList = [];
      this.RenitializeMediaTypes();
      this.ProductDetails = {
        ProductName: null,
        ProductCode: null,
        TruncatedProductName: null,
        AddedBillingException: false,
        AddedCommissionException: false,
        ProductBillingAddress: {
          Country: null,
          CountryName: null,
          City: null,
          State: null,
          StateName: null,
          ZipCode: null,
          AddressLine1: null,
          AddressLine2: null,
          StreetAddress: null,
          TruncatedBillingStreetAddress: null,
          Attn: null,
          BillToName:null
        },
        CommissionInfo: {
          FeeOnly: null,
          CommissionBasis: null,
          CommissionPercentage: null,
          TimeOnly: null,
        },
        Media: [],
      };
    },
    ReloadNETMedia() {
      this.NETList = [];
      NETMedia.forEach((val) => {
        this.NETList.push({ ...val });
      });
    },
    ReloadSPOTMedia() {
      this.SPOTList = [];
      SPOTMedia.forEach((val) => {
        this.SPOTList.push({ ...val });
      });
    },
    ReloadPRINTMedia() {
      this.PRINTList = [];
      PRINTMedia.forEach((val) => {
        this.PRINTList.push({ ...val });
      });
    },
    ShowAddProductModal() {
      this.RenitializeMediaTypes();
      this.ShowAddProduct = true;
      this.ModalTitle = "Add Product";
    },
    SaveProduct() {
      //store.commit("client/SaveProductToList", this.ProductDetails,this.EditIndex);
      this.AddUsedMedia();
      this.AssignDropdownNames(this.ProductDetails);
      this.$set(this.ClientProductList, this.EditIndex, this.ProductDetails);
      this.CancelAdd();
      this.InitiateObject();
    },
    AddSelectedMedia() {
      this.ProductDetails.Media = [];
      this.NETList.forEach((element) => {
        if (element.Selected)
          this.ProductDetails.Media.push({
            MediaCategory: this.NetMedia,
            MediaType: element.MediaType,
          });
      });
      this.PRINTList.forEach((element) => {
        if (element.Selected)
          this.ProductDetails.Media.push({
            MediaCategory: this.PrintMedia,
            MediaType: element.MediaType,
          });
      });
      this.SPOTList.forEach((element) => {
        if (element.Selected)
          this.ProductDetails.Media.push({
            MediaCategory: this.SpotMedia,
            MediaType: element.MediaType,
          });
      });
    },
    AddUsedMedia() {
      this.ProductDetails.Media = [];
      this.$set(this.ProductDetails, "Media", []);
      this.SelectedNETList.forEach((element) => {
        this.ProductDetails.Media.push({
          MediaCategory: this.NetMedia,
          MediaType: element.toString(),
        });
      });
      this.SelectedPRINTList.forEach((element) => {
        this.ProductDetails.Media.push({
          MediaCategory: this.PrintMedia,
          MediaType: element.toString(),
        });
      });
      this.SelectedSPOTList.forEach((element) => {
        this.ProductDetails.Media.push({
          MediaCategory: this.SpotMedia,
          MediaType: element.toString(),
        });
      });
    },

    AssignDropdownNames(obj) {
      if (obj.ProductBillingAddress.Country != null) {
        let countrySelected = this.CountryList.findIndex((val) => {
          return val.value == obj.ProductBillingAddress.Country;
        });
        if (countrySelected > -1) {
          this.$set(
            obj.ProductBillingAddress,
            "CountryName",
            this.CountryList[countrySelected].label.split("-")[1]
          );
        }
      }
      if (obj.ProductBillingAddress.State != null) {
        let countrySelected = this.Stateilst.findIndex((val) => {
          return val.value == obj.ProductBillingAddress.State;
        });
        if (countrySelected > -1) {
          this.$set(
            obj.ProductBillingAddress,
            "StateName",
            this.Stateilst[countrySelected].label
          );
        }
      }
    },
    ResetTable() {
      this.ClientProductList = [];
      this.AddAnotherProduct();
    },
    AddProductUploadedProduct() {
      store.commit("client/AddProductToList", this.ProductDetails);
      this.InitiateObject();
    },
    AddAnotherProduct() {
      store.commit("client/AddProductToList", this.ProductDetails);
      this.InitiateObject();
      this.currentPage = Math.ceil(
        this.ClientProductList.length / this.perPage
      );
    },
    AddProduct() {
      //window.console.log('test add product billing address' + JSON.stringify(this.ProductDetails));
      this.AddUsedMedia();
      this.AssignDropdownNames(this.ProductDetails);
      store.commit("client/AddProductToList", this.ProductDetails);
      this.CancelAdd();
      this.InitiateObject();
    },
    CancelAdd() {
      this.showtrucatedbilling = false;
      this.ShowAddProduct = false;
      this.ShowBillingException = false;
      this.ShowCommissionException = false;
      this.EditIndex = -1;
      this.InitiateObject();
    },
    AddBillingException() {
      this.ShowBillingException = true;
      this.ShowAddExceptionsPopover = false;
      this.ProductDetails.AddedBillingException = true;
    },
    AddCommissionException() {
      this.ShowCommissionException = true;
      this.ShowAddExceptionsPopover = false;
      this.ProductDetails.AddedCommissionException = true;
    },
    LoadSelectedMedia(mediaList, mediaSelected) {
      mediaList.forEach((val) => {
        let indexMedia = mediaSelected.findIndex((media) => {
          return media.MediaType == val.MediaType;
        });
        if (indexMedia > -1) {
          this.$set(val, "Selected", true);
        }
      });
    },
    LoadUsedMedia(mediaList, mediaSelected, selectedList) {
      mediaList.forEach((val) => {
        let indexMedia = mediaSelected.findIndex((media) => {
          return media.MediaType == val.MediaType;
        });
        if (indexMedia > -1) {
          selectedList.push(val.MediaType);
        }
      });
    },
    LoadProductDetails() {
      let currentProduct = this.ClientProductList[this.EditIndex];
      Object.assign(this.ProductDetails, currentProduct);
      this.$set(
        this,
        "ShowCommissionException",
        currentProduct.AddedCommissionException
      );
      this.$set(
        this,
        "ShowBillingException",
        currentProduct.AddedBillingException
      );
      let selectedMedia = currentProduct.Media;
      if (selectedMedia && selectedMedia.length > 0) {
        this.LoadUsedMedia(NETMedia, selectedMedia, this.SelectedNETList);
        this.LoadUsedMedia(PRINTMedia, selectedMedia, this.SelectedPRINTList);
        this.LoadUsedMedia(SPOTMedia, selectedMedia, this.SelectedSPOTList);
      }
    },
    EditProduct(index) {
      this.ShowAddProduct = true;
      this.ModalTitle = "Edit Product";
      this.EditIndex = index;
      this.LoadProductDetails();
    },
    DeleteProduct(index) {
      this.ShowDelete = true;
      this.DeleteProductTitle = `Are you sure you want to delete ${
        this.ClientProductList[index + (this.currentPage - 1) * this.perPage]
          .ProductName
      }?`;
      this.DeleteIndex = index;
    },
    DeleteConfirm() {
      store.commit(
        "client/RemoveProduct",
        this.DeleteIndex + (this.currentPage - 1) * this.perPage
      );
      this.ShowDelete = false;
      this.DeleteIndex = -1;
      this.DeleteProductTitle = null;
    },
    CancelDelete() {
      this.ShowDelete = false;
      this.DeleteIndex = -1;
      this.DeleteProductTitle = null;
    },
    NavigateToRoute(name) {
      if (this.id) {
        this.$router.push({ name: name, params: { id: this.id,isDraft:this.isDraft } });
      } else {
        this.$router.push({ name: name });
      }
    },
    Next() {
      this.$parent.$emit("stepChanged", this.$route.meta.tabIndex);
      this.NavigateToRoute("Review");
    },

    SaveasDraft(){
         this.$store
        .dispatch("client/SaveClientDraft",this.isDraft==true?parseInt(this.id):null)
        .then((res) => {
          window.console.log(JSON.stringify(res));
           if (res.status == 200) {
               store.commit("progress/removeProcessing", {
               root: true
           });
          store.dispatch(
            "progress/setSuccessMessage",
            `The Client request have been saved as Draft successfully`
          );

          this.$router.push("/ClientProduct");
        }
        else{
          window.console.log('error while uploading files');
        }

        })
        .catch((ex) => {
          this.$store.dispatch(
            "progress/setErrorMessage",
            `Error while Saving Client as Draft. ${ex}`
          );
        });
    },


    Previous() {
      if (this.BillingPartyNotTimeOnly) {
        this.NavigateToRoute("Commission Info");
      } else {
        this.NavigateToRoute("Billing");
      }
    },

    // BillingAddressremaincharCount: function () {
    //   if (this.ProductDetails.ProductBillingAddress.StreetAddress.length > 26) {
    //     this.showtrucatedbilling =true;
    //     // this.showtruncated = true;
    //     // this.showtruncationvalidationmessage = true;
    //     this.ProductDetails.ProductBillingAddress.TruncatedBillingStreetAddress = this.ProductDetails.ProductBillingAddress.StreetAddress.slice(
    //       0,
    //       26
    //     );
    //   } else {
    //     this.showtrucatedbilling =false;
    //    // this.showtruncated = false;
    //    // this.showtruncationvalidationmessage = false;
    //   //  this.ClientDetails.TruncatedClientName = "";
    //   }
    // },

    SaveForLater() {},
    Cancel() {
      var guid = localStorage.getItem("UploadfileId");
      if (guid != "") {
        service
          .removeDiretory(guid)
          .then(() => {
            this.$router.push("/ClientProduct");
            localStorage.setItem("UploadfileId", "");
          })
          .catch((err) => {
            alert(err);
            this.$router.push("/ClientProduct");
          });
      } else {
        this.$router.push("/ClientProduct");
      }
    },
  },
};
</script>

<style scoped>

.table td .error-message-format{
margin-top:-5px;
margin-bottom:5px;
}
.table td > input {
  margin-top:5px;
  margin-bottom:5px;
}
.add-product-row {
  font-weight: bold;
  padding-left: 0px;
}
.products-upload-card * {
  font-family: "Open Sans";
  font-size: 14px !important;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.drag-and-drop-file-h {
  /* height: 50px;
  padding: 5px 90px 20px 25px;
  border-radius: 8px;
  border: solid 1px #e9eef0; */
  background-color: #ffffff;
  padding: 10px 0px 0px 10px;
}
.dropzone-custom-content,
.drag-and-drop-file-h,
.dz-message {
  height: 100% !important;
}
.draganddropspan {
  color: #00a1d2;
}

.products-upload-card section > label {
  text-transform: none;
  font-weight: 600;
}
.products-upload-card section > ol {
  margin-left: 1.5rem;
}
.customdropzone > .dz-preview dz-file-preview {
  border-top: dashed 2px #e9eef0 !important;
}

.customdropzone {
  border-radius: 8px !important;
  border: dashed 2px #e9eef0 !important;
  background-color: #ffffff !important;
  cursor: pointer !important;
  text-align: center;
  min-height: 50px;
}
.SUGGESTED-CLIENT-NAM {
  width: 179px;
  height: 19px;
  margin-left: -1px;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #77a2bb !important;
}

.Due-to-character-res {
  width: 431px !important;
  height: 15px !important;
  font-family: "Open Sans" !important;
  font-size: 12px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.25 !important;
  letter-spacing: normal !important;
  color: #4d5161 !important;
}
.delete-exception {
  height: 10px;
  float: right;
  display: block;
}
.exception-close {
  display: block;
  padding-bottom: 14px !important;
}
.button-add-product {
  padding-bottom: 10px;
}
div.table-mandatory {
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #4d5161;
}
.table.b-table > thead > tr > [aria-sort="none"],
.table.b-table > tfoot > tr > [aria-sort="none"],
.table.b-table > thead > tr > [aria-sort="ascending"],
.table.b-table > tfoot > tr > [aria-sort="ascending"],
.table.b-table > thead > tr > [aria-sort="descending"],
.table.b-table > tfoot > tr > [aria-sort="descending"] {
  background-position-y: 25px !important;
  background-position-x: 95% !important;
}
.inline-custom-radio-commission {
  display: inline !important;
  margin: 0 0.25rem !important;
}
.commission-basis-with-fee .card {
  padding-left: 0px !important;
}
.ClientRectangle {
  border-radius: 5px !important;
  background-color: rgba(216, 216, 216, 0.2) !important;
  margin-left: -2% !important;
  height: 178px !important;
}

.defaultClientRectangle {
  border-radius: 5px !important;
  margin-left: 0% !important;
}
.product-items tbody td input {
  margin: 5px;
}

.truncatedvalidationmdg {
  margin-left: 13px !important;
  font-size: 10px !important;
  color: #df5678 !important;
  font-family: "Open Sans" !important;
  font-size: 11px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: italic !important;
  line-height: 0.8 !important;
  letter-spacing: normal !important;
  text-align: right !important;
}
.excel-file-upload-steps li {
  list-style: decimal;
}
.excel-file-upload-steps li a {
  color: #00a1d2;
  cursor: pointer !important;
  border: none !important;
  padding: 0px;
}
.excel-file-upload-steps li a:hover {
  color: #00a1d2;
  opacity: 0.7;
  cursor: pointer !important;
}
.products-upload-card > .card-body {
  border-radius: 5px;
}
.redtextbox {
  height: 35px !important;
  border: 1px solid #df5678 !important;
  background: center right 7px no-repeat
    url("../../assets/Icons/error-icon.svg") #fff !important;
}

.panel-top label {
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  color: #2c3865;
}
.panel-top > label {
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.57;
  letter-spacing: normal;
  color: #77a2bb;
  padding-left: 0px !important;
}
.panel-top {
  border-bottom: solid 1px rgba(151, 151, 151, 0.2);
}

.modal .popover-body > p:first-child {
  padding-top: 15px !important;
}
.modal .popover-body > p:nth-child(2) {
  margin-bottom: 0px !important;
  padding-bottom: 7px !important;
}

.unselected-default-text {
  padding: 40px 10px;
}

.custom-dropdown-bottom.form-group {
  margin-bottom: 0px !important;
}
.custom-dropdown-bottom.form-group > div.form-group {
  margin-bottom: 0.5rem !important;
}
.floating-label-dropdown.floating-label-input {
  margin-top: 0px !important;
}
.custom-dropdown {
  margin-bottom: 0rem !important;
}
.commission-exception {
  margin-top: 10px !important;
}
.child-component > div:first-child {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.row.vertical-line:after {
  content: "";
  position: absolute;
  z-index: 0;
  top: 3rem;
  bottom: 22vh;
  left: 50%;
  border-left: 1px solid rgba(151, 151, 151, 0.19);
  transform: translate(-50%);
  height: 3.5rem;
}
.row.vertical-line > div {
  padding: 0px !important;
}
.custom-link-button {
  color: #00a1d2 !important;
  font-family: "Open Sans";
  font-size: 12px;
}
.card.secondary-card label:not(.btn-outline-primary) {
  font-family: "Open Sans";
  font-weight: 600;
}

.card.secondary-card .card-body {
  padding: 10px !important;
}
.card.secondary-card .card-body {
  padding: 10px !important;
}
.card.secondary-card .card-body > div.row:first-child {
  padding-top: 4px !important;
}
div.primary-content label {
  font-family: "Open Sans";
  font-weight: 600;
  color: #4d5161;
}
span.circle-data {
  padding: 3px 7px;
  border-radius: 50% 50%;
  border: 1px solid #018db8;
  background-color: #018db8;
  color: #fff;
  font-family: "Open Sans";
}
.exception-circle .btn {
  padding: 0.25rem 0.25rem !important;
}
.exception-title {
  text-transform: uppercase !important;
  font-family: "Open Sans";
  font-weight: 700;
  color: #00a1d2 !important;
  font-size: 10px;
}
.exception-body {
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 12px;
}

.commission-basis {
  font-family: "Open Sans" !important;
  font-size: 10px !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.8;
  letter-spacing: normal;
  color: #4d5161;
}
.modal-body .card-body {
  padding: 0.75rem !important;
  /* padding-bottom: 5px !important;   */
}
.row.vertical-line > div:first-child > fieldset {
  margin-bottom: -0.75rem !important;
}
.select-default {
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  color: #4d5161;
}
</style>